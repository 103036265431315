import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ConsultationPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"consultation-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"left"} width={"8"}>
              <Header as={"h1"} textAlign={"center"}>
                Consultation
              </Header>
              <ContactForm
                destinationEmail={"stilettohairstudiovb@gmail.com"}
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "firstVisit",
                    displayLabel:
                      "Is this your first visit to Stiletto Hair Studio?",
                    placeholder:
                      "Is this your first visit to Stiletto Hair Studio?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "referral",
                    displayLabel: "Were you referred by anyone?",
                    placeholder: "Were you referred by anyone?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "lastTimeHairWasDone",
                    displayLabel:
                      "When was the last time your hair was done professionally?",
                    placeholder:
                      "When was the last time your hair was done professionally?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "atHomeColor",
                    displayLabel:
                      "Does your current hair have any at-home color on it?",
                    placeholder:
                      "Does your current hair have any at-home color on it?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "whenWasItLastDone",
                    displayLabel:
                      "Does your hair currently have any color and when was it last done?",
                    placeholder:
                      "Does your hair currently have any color and when was it last done?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "hairThickness",
                    displayLabel: "How would you describe your hair thickness?",
                    placeholder: "How would you describe your hair thickness?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "hairLength",
                    displayLabel: "How would you describe your hair length?",
                    placeholder: "How would you describe your hair length?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "hairTexture",
                    displayLabel: "How would you describe your hair texture?",
                    placeholder: "How would you describe your hair texture?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "appointmentPurpose  ",
                    displayLabel:
                      "What are you trying to achieve with your hair at your appointment?",
                    placeholder:
                      "What are you trying to achieve with your hair at your appointment?",
                    type: "input",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Consultation" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
